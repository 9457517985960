import { lazy } from "react";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Icon from "@mui/material/Icon";
import { role } from "utils/constraints";

const Dashboard = lazy(() => import("layouts/dashboard"));
const Users = lazy(() => import("layouts/users"));
const Admins = lazy(() => import("layouts/admins"));
const Banners = lazy(() => import("layouts/banners"));
const ToolsAdsBanners = lazy(() => import("layouts/toolsAdsBanners"));
const Banks = lazy(() => import("layouts/banks"));
const Branches = lazy(() => import("layouts/branches"));
const Categories = lazy(() => import("layouts/categories"));
const VerifiedListings = lazy(() => import("layouts/verifiedListings"));
const UnverifiedListings = lazy(() => import("layouts/unverifiedListings"));
const SoldListings = lazy(() => import("layouts/soldListings"));
const RejectedListings = lazy(() => import("layouts/rejectedListings"));
const SuperFeaturedListings = lazy(() => import("layouts/SuperFeaturedListings"));
const Leads = lazy(() => import("layouts/leads"));
const Testimonials = lazy(() => import("layouts/testimonials"));
const Demands = lazy(() => import("layouts/demands"));
const Careers = lazy(() => import("layouts/careers"));
const ShortTermRental = lazy(() => import("layouts/shortTermRental"));

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Admins",
    key: "admins",
    icon: <Icon fontSize="small">admin_panel_settings</Icon>,
    route: "/admins",
    component: <Admins />,
  },
  {
    type: "collapse",
    name: "Banners",
    key: "banners",
    icon: <Icon fontSize="small">images</Icon>,
    route: "/banners",
    component: <Banners />,
  },
  {
    type: "collapse",
    name: "Ads Banners",
    key: "ads-banner",
    icon: <Icon fontSize="small">add_to_photos</Icon>,
    route: "/ads-banner",
    component: <ToolsAdsBanners />,
  },
  {
    type: "collapse",
    name: "Careers",
    key: "careers",
    icon: <Icon fontSize="small">work</Icon>,
    route: "/careers",
    component: <Careers />,
  },
  {
    type: "collapse",
    name: "Banks",
    key: "banks",
    icon: <Icon fontSize="small">account_balance</Icon>,
    route: "/banks",
    component: <Banks />,
  },
  {
    type: "collapse",
    name: "Branches",
    key: "branches",
    icon: <Icon fontSize="small">account_tree</Icon>,
    route: "/branches",
    component: <Branches />,
  },
  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/categories",
    component: <Categories />,
  },
  {
    type: "collapse",
    name: "Verified Listings",
    key: "verified-listings",
    icon: <Icon fontSize="small">verified</Icon>,
    route: "/verified-listings",
    component: <VerifiedListings />,
  },
  {
    type: "collapse",
    name: "Unverified Listings",
    key: "unverified-listings",
    icon: <Icon fontSize="small">cancel</Icon>,
    route: "/unverified-listings",
    component: <UnverifiedListings />,
  },
  {
    type: "collapse",
    name: "Sold Listings",
    key: "sold-listings",
    icon: <Icon fontSize="small">check</Icon>,
    route: "/sold-listings",
    component: <SoldListings />,
  },
  {
    type: "collapse",
    name: "Rejected Listings",
    key: "rejected-listings",
    icon: <Icon fontSize="small">reply</Icon>,
    route: "/rejected-listings",
    component: <RejectedListings />,
  },
  {
    type: "collapse",
    name: "Super Featured Listings",
    key: "super-featured-listings",
    icon: <Icon fontSize="small">star_filled</Icon>,
    route: "/super-featured-listings",
    component: <SuperFeaturedListings />,
  },
  {
    type: "collapse",
    name: "Leads",
    key: "leads",
    icon: <Icon fontSize="small">star_border</Icon>,
    route: "/leads",
    component: <Leads />,
  },
  {
    type: "collapse",
    name: "Testimonials",
    key: "testimonials",
    icon: <Icon fontSize="small">add_comments</Icon>,
    route: "/testimonials",
    component: <Testimonials />,
  },
  {
    type: "collapse",
    name: "Demands",
    key: "demands",
    icon: <Icon fontSize="small">live_help</Icon>,
    route: "/demands",
    component: <Demands />,
  },
  {
    type: "collapse",
    name: "Short Term Rental",
    key: "short-term-rental",
    icon: <Icon fontSize="small">apartment</Icon>,
    route: "/short-term-rental",
    component: <ShortTermRental />,
  },
  {
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">apartment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

// Filter out Users route if role is branchadmin
const filteredRoutes =
  role === "branchadmin"
    ? routes.filter(
        (route) =>
          route.key !== "users" &&
          route.key !== "admins" &&
          route.key !== "banners" &&
          route.key !== "ads-banner" &&
          route.key !== "careers" &&
          route.key !== "banks" &&
          route.key !== "branches" &&
          route.key !== "categories" &&
          route.key !== "testimonials" &&
          route.key !== "super-featured-listings" &&
          // route.key !== "unverified-listings" &&
          route.key !== "rejected-listings" &&
          route.key !== "demands"
      )
    : routes;

export default filteredRoutes;
